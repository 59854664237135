<template>
  <div class="player-container">
    <div
      v-if="audio !== '' && showUi"
      class="audio-player"
    >
      <div class="main-controls">
        <a
          class="has-text-black"
          href="#"
          @click.prevent="playClicked"
        >
          <b-icon
            v-if="!isPlaying"
            pack="fas"
            icon="play"
            size="is-small"
          />
          <b-icon
            v-if="isPlaying"
            pack="fas"
            icon="pause"
            size="is-small"
          />
        </a>
        <a
          v-if="showStopBtn"
          class="has-text-black"
          href="#"
          @click.prevent="stopClicked"
        >
          <b-icon
            pack="fas"
            icon="stop"
            size="is-small"
          />
        </a>
      </div>
      <div
        ref="trackBar"
        class="track-bar"
        @click="changeCurrentTime"
      >
        <a
          ref="trackBall"
          class="track-ball"
          href="#"
        />
      </div>
      <div class="secondary-controls">
        <div class="time-labels">
          {{ currentTimeLabel }} / {{ totalTimeLabel }}
        </div>
        <a
          v-if="showTempo"
          class="tempo-selector-btn has-text-black"
          href="#"
          @click.prevent="showTempoSelector"
        >
          <b-icon
            pack="fas"
            icon="clock"
            size="is-small"
          />
        </a>
      </div>
    </div>
    <b-modal
      v-model="showTempoModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Selección tempo"
      aria-modal
    >
      <template #default="props">
        <tempo-selector
          v-bind="tempoData"
          @close="props.close"
          @change="tempoChanged"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import TempoSelector from "@/components/modals/TempoSelector";
import NumberUtils from '@/utils/number'
import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

export default {
  name: "AudioPlayer",
  components: {
    TempoSelector
  },
  props: {
    hasTempo: {
      type: Boolean,
      default: false
    },
    playbackRate: {
      type: Number,
      default: 1
    },
    audio: {
      type: String,
      default: ''
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    showUi: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      REFRESH_TIME: 250,
      audioEngine: null,
      isPlaying: false,
      isPaused: false,
      loaded: false,
      trackbarInt: null,
      totalAudioTime: null,
      currentAudioTime: null,
      showTempo: this.hasTempo,
      showTempoModal: false,
      tempoData: {
        tempo: this.playbackRate
      }
    }
  },
  computed: {
    currentTimeLabel() {
      if (!this.audioEngine) {
        return this.secondsToTimeLabel()
      }

      return this.secondsToTimeLabel(this.currentAudioTime)
    },
    totalTimeLabel() {
      return this.secondsToTimeLabel(this.totalAudioTime)
    },
    showStopBtn() {
      return this.isPlaying === true || this.isPaused === true
    }
  },
  watch: {
    hasTempo(newVal){
      this.showTempo = !!newVal
    },
    audio() {
      this.initPlayer()
    }
  },
  mounted() {
    this.initPlayer()
  },
  beforeDestroy(){
    this.stopClicked()
  },
  methods: {
    playClicked() {
      if (this.loaded) {
        if (!this.isPlaying) {
          noSleep.enable();
          this.audioEngine.play()
        }

        if (this.isPlaying) {
          noSleep.disable();
          this.audioEngine.pause()
        }
      }
    },
    stopClicked() {
      if (this.loaded) {
        this.stopAudio()
      }
    },
    initPlayer() {
      if (!this.audio) {
        return
      }
      console.info("init player with: " + this.audio)
      if (this.trackbarInt) {
        clearInterval(this.trackbarInt);
      }
      this.audioEngine = new Audio()
      this.audioEngine.playbackRate = this.tempoData.tempo
      this.audioEngine.src = this.audio
      this.audioEngine.oncanplaythrough = () => {
        this.currentAudioTime = this.audioEngine.currentTime
        this.totalAudioTime = this.audioEngine.duration

        if (this.autoPlay) {
          //this.playAudio()
        }

        this.$emit('loaded', {
          totalTime: this.audioEngine.duration,
          currentTime: this.currentAudioTime,
          playbackrate: this.tempoData.tempo
        })
      }
      this.audioEngine.load = () => {
        this.loaded = true
        this.isPlaying = false
        this.isPaused = false
      }

      this.audioEngine.onplaying = this.playAudio
      this.audioEngine.onpause = this.pauseAudio
      this.audioEngine.onended = this.endedAudio

      this.audioEngine.load()
    },
    playAudio() {
      this.isPlaying = true
      this.isPaused = false

      clearInterval(this.trackbarInt)

      this.trackbarInt = setInterval(() => {
        this.refreshProgress()
      }, this.REFRESH_TIME)

      this.$emit('change-status', 'play')
    },
    pauseAudio() {
      if (this.isPlaying) {
        clearInterval(this.trackbarInt)
        this.isPaused = true
        this.isPlaying = false

        this.$emit('change-status', 'pause')
      }
    },
    stopAudio() {
      if (this.isPlaying || this.isPaused) {
        noSleep.disable();
        this.audioEngine.pause()
        clearInterval(this.trackbarInt)
        this.currentAudioTime = 0
        this.audioEngine.currentTime = 0
        this.isPlaying = false
        this.isPaused = false
        this.refreshProgress()
        this.$emit('change-status', 'stop')
      }

    },
    endedAudio() {
      this.stopAudio()

      //todo: comprobar si provoca algún error en interactivos antes de activar
      //this.$emit('change-status', 'ended')
    },
    refreshProgress() {
      this.currentAudioTime = this.audioEngine.currentTime

      if (this.showUi) {
        const percent = NumberUtils.getPercent(this.currentAudioTime, this.totalAudioTime)
        const trackBallWidth = this.$refs.trackBall.clientWidth
        const trackBarWidth = this.$refs.trackBar.clientWidth
        const trackBarPos = Math.round(trackBarWidth * percent / 100) - (trackBallWidth / 2)


        //this.$refs.trackBall.style.left = percent + '%'
        this.$refs.trackBall.style.left = trackBarPos + 'px'
      }

      const timeEvent = {
        interval: this.REFRESH_TIME / 1000,
        current_time: this.currentAudioTime,
        total_time: this.totalAudioTime,
        playback_rate: this.tempoData.tempo
      }

      this.$emit('change-cue', timeEvent)
    },
    secondsToTimeLabel(timeInSeconds = null) {
      if (null === timeInSeconds) {
        return "00:00"
      }

      let minutes = Math.floor(Math.round(timeInSeconds) / 60)
      let seconds = Math.round(timeInSeconds - minutes * 60)
      if (seconds < 0) {
        seconds = 0
      }

      return this.strPadLeft(minutes, '0', 2) + ':' + this.strPadLeft(seconds, '0', 2)
    },
    strPadLeft(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length)
    },
    showTempoSelector() {
      this.showTempoModal = true
    },
    tempoChanged(tempo) {
      if (tempo) {
        this.audioEngine.playbackRate = tempo.value
        this.tempoData.tempo = tempo.value
      }
    },
    changeCurrentTime(event) {
      const offsetX = this.$refs.trackBar.offsetLeft
      //const trackBallWidth = this.$refs.trackBall.clientWidth
      const trackBarWidth = this.$refs.trackBar.clientWidth
      const leftPos = (event.clientX - offsetX) > 0 ? (event.clientX - offsetX) : 0;
      let newCurrentPercent = (leftPos * 100) / trackBarWidth;
      newCurrentPercent = newCurrentPercent > 100 ? 100 : newCurrentPercent
      //const newCurrentTime = (this.totalAudioTime * newCurrentPercent) / 100
      //console.info(leftPos, trackBarWidth, "--->", newCurrentPercent, newCurrentTime)

      //this.audioEngine.currentTime = newCurrentTime;
      this.audioEngine.currentTime = (this.totalAudioTime * newCurrentPercent) / 100;

      this.refreshProgress()
    }
  }
}
</script>

<style scoped lang="scss">
·player-container{
  transition: 0.5s ease-in-out;
}
.audio-player {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #eeeeee;
  padding: 0.25rem 0.5rem;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 38px;

  .main-controls {
    display: flex;
    align-items: center;
    min-width: 50px;

    a {
      display: flex;
      margin: 0 0.2rem;
    }
  }

  .track-bar {
    flex: 1;
    margin-left: 1rem;
    margin-right: 1rem;
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAeCAYAAAGakkRRAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDE3MUQ4NkQzRTNEMTFFNzlBMkNGMjRDM0Y2MEQwQUEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDE3MUQ4NkUzRTNEMTFFNzlBMkNGMjRDM0Y2MEQwQUEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MTcxRDg2QjNFM0QxMUU3OUEyQ0YyNEMzRjYwRDBBQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTcxRDg2QzNFM0QxMUU3OUEyQ0YyNEMzRjYwRDBBQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PibS1EkAAAAtSURBVHjaYvj//z8DEwMQIAiAAGLAIgYQQFjE/oMIRoQYQIBh04aFQNeGjQAA5AQOKazlkZcAAAAASUVORK5CYII=) center 0 repeat-x scroll;
    height: 30px;
    position: relative;
    cursor: pointer;

    .track-ball {
      position: absolute;
      background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAAH6ji2bAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDE3MUQ4NzEzRTNEMTFFNzlBMkNGMjRDM0Y2MEQwQUEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDE3MUQ4NzIzRTNEMTFFNzlBMkNGMjRDM0Y2MEQwQUEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MTcxRDg2RjNFM0QxMUU3OUEyQ0YyNEMzRjYwRDBBQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTcxRDg3MDNFM0QxMUU3OUEyQ0YyNEMzRjYwRDBBQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvlgDcsAAAHRSURBVHjaYgydd4wBCPqBuJAFSMwD4kQgVgFxkoBYFCQNEECMUGUlINHNQKzLxAABlwECCCa1mQEJgGSroGxdIGYGMQACCKbSEogrgDgQiP+xoGndCMQNTEgCPlAaRfA7skVboOy9UNoXIIBgFoEk1wIxCwMmWAPEC0EK7KGWYlMEAiEgB4IkC9AkYA77CcS7YYIghaFAvB5J4RYspj4HKfwDciwQKwDxZHQFQJwGYgAEEMwzMA+VAbE1EN8B4lVAfBzZahAIA+JYJJNUkOLCF2aKLZoidLAZ2TpCwBQ97AyBWBqL7+OY0BSK4zBREF3hHiD+AcT30MTPgKx+C8TCUIE/UMXoYBLIxAQCHgElin9MSGH1AIuiFlDKATEAAgw5ZmBAFppQ1AjY9BSI5wPxSWRB5GBMBWI/BuIBKLhroGxQslgMi0BYpibFMHQASlIzQOaBDCuBFQpYABsQawOxGJT/AoivQmMFm4szQQaa47HZCS1YZKGJZhcO9ZYgL7/HY+AzLGJP8Kh/zwKN7hIcCi5BMbFgEciFB4G4i4Fy0ADEp2EJ9jAQ+0Oj/g+JBq2BJviz6OnwHzQ9rYKKm0MLD0kgVoKquQNN0EehCfofuukAQqlb83rSnukAAAAASUVORK5CYII=) 0 0 no-repeat scroll;
      left: -20px;
      top: 5px;
      height: 20px;
      width: 20px;
    }
  }

  .secondary-controls {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .time-labels {
      font-size: 0.75rem;
      padding-right: 0.25rem;
    }

    .tempo-selector-btn {
      display: flex;
      margin: 0 0.2rem;
    }
  }
}
</style>
