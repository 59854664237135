<template>
  <b-modal
    v-model="showModal"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Modal texto"
    aria-modal
    @close="close"
  >
    <template #default="props">
      <div
        class="modal-card"
        style="width: auto"
      >
        <section
          class="modal-card-body modal-text-container"
          v-html="content"
        />
        <footer class="modal-card-foot">
          <b-button
            label="Volver"
            @click="close"
          />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "TextModal",
  props: {
    show: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      content: this.value,
      showModal: this.show
    }
  },
  watch: {
    value: function (val) {
      this.content = val
    },
    show: function (val) {
      this.showModal = val
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.modal-text-container ol, .modal-text-container ul {
  margin: 0 20px !important;
}
</style>
