<template>
  <div class="text-container">
    <a
      v-if="itemIsActive"
      class="remove-btn"
      href="#"
      @click.prevent="$emit('remove',item)"
    ><b-icon
      pack="fas"
      icon="times"
      size="is-small"
    />
    </a>
    <div
      v-if="!textActive"
      class="text"
      :style="textStyles()"
      @active="onActive()"
      v-html="$options.filters.nl2br(text)"
    />
    <textarea
      v-else
      ref="area"
      v-model="text"
      class="textarea"
      @blur="onBlur"
    />
  </div>
</template>

<script>

export default {
  name: 'TextBox',
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  data() {
    return {
      defaultText: 'Dame dos veces para editarme',
      text: 'Dame dos veces para editarme',
      textActive: false
    }
  },
  computed: {
    itemIsActive(){
      return this.item.selected === true
    }
  },
  watch: {
    item(newVal) {
      this.text = newVal?.text || this.defaultText
    }
  },
  mounted() {
    this.$on('active', this.onActive)
  },
  methods: {
    onActive() {
      this.textActive = true
      this.$nextTick(() => {
        this.$refs.area.focus()
        /*if (this.text === this.defaultText) {
          this.text = ''
        }*/
      })
    },
    onBlur() {
      this.textActive = false
      this.$parent.$emit('content-inactive')
    },
    textStyles(){
      let styles = {
      }

      if(this.item?.textConfig?.family){
        styles['fontFamily'] = this.item.textConfig.family
      }
      if(this.item?.textConfig?.size){
        styles['fontSize'] = this.item.textConfig.size
      }
      if(this.item?.textConfig?.color){
        styles['color'] = this.item.textConfig.color
      }
      if(this.item?.textConfig?.align){
        styles['textAlign'] = this.item.textConfig.align
      }
      if(this.item?.textConfig?.backgroundColor){
        styles['backgroundColor'] = this.item.textConfig.backgroundColor
      }

      return styles
    }
  }
}
</script>

<style scoped lang="scss">
.text-container{
  position: relative;
  height: 100%;

  .remove-btn{
    position: absolute;
    right: 15px;
    top: 0;
    transform: translateY(-50%);
    background-color: darkred;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .textarea {
    position: absolute;
    width: 100%;
    height: 100%;
    resize: none;
  }
}

</style>
