class DeviceUtils {

  constructor() {
  }

  get userAgent() {
    return navigator.userAgent
  }

  isMobileIOS() {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
  }
}

export default new DeviceUtils()
