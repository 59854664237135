class AudioUtils {
  getPlaybackRatedDuration(playbackRate, duration) {
    if (playbackRate === 1) {
      return duration
    }

    const diffAmount = duration * playbackRate

    if(playbackRate < 1){
      return duration + diffAmount
    }

    if(playbackRate > 1){
      return duration - diffAmount
    }
  }
}

export default new AudioUtils()
