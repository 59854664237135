<template>
  <div
    class="scene-asset"
    :class="assetClasses(asset)"
    :style="positionStyles"
  >
    <img
      v-if="showOverlay"
      ref="overlay-asset"
      class="overlay"
      :src="getAssetFullPath(currentOverlay)"
      :alt="asset.id"
      :style="sizeStyles"
    >
    <img
      v-if="asset && loaded"
      ref="asset"
      class="image"
      :class="getViewClasses()"
      :src="getAssetFullPath(currentImage)"
      :alt="asset.id"
      :style="sizeStyles"
      @click="clicked"
    >
  </div>
</template>

<script>

export default {
  name: "MediaAsset",
  props: {
    data: {
      type: Object,
      default: null
    },
    scale: {
      type: Number,
      default: 1
    },
    scene: {
      type: Number,
      default: null
    },
  },
  data: function () {
    return {
      STATUS_ENABLED: 'enabled',
      STATUS_DISABLED: 'disabled',
      asset: this.data,
      behaviours: this.data.behaviours,
      loaded: false,
      status: 'disabled',
      showOverlay: false,
      currentImage: this.data.img,
      currentOverlay: ''
    }
  },
  computed: {
    positionStyles() {
      return 'left: ' + (this.asset.x * this.scale) + 'px;top: ' + (this.asset.y * this.scale) + 'px;width: ' + (this.asset.width * this.scale) + 'px;height: ' + (this.asset.height * this.scale) + 'px;'
    },
    sizeStyles() {
      return 'width: ' + (this.asset.width * this.scale) + 'px;height: ' + (this.asset.height * this.scale) + 'px;'
    },
    hasTouchBehaviours() {
      const behaviourClickIndex = this.behaviours.findIndex((item) => {
        return item.attached_to === 'click'
      })

      return behaviourClickIndex >= 0
    }
  },
  mounted() {
    this.loaded = true

    this.initCueEvents()

    this.initControlEvents()
  },
  methods: {
    getAssetFullPath(assetPath) {
      return process.env.VUE_APP_BASE_CDN + assetPath
    },
    getBehavioursByType(type = 'click') {
      return this.behaviours.filter((item) => {
        return item.attached_to === type
      })
    },
    getBehavioursByKey(key) {
      return this.behaviours.filter((item) => {
        return item.key === key
      })
    },
    assetClasses() {
      let classList = []

      if (this.hasTouchBehaviours) {
        classList.push('handled')
      }

      return classList.join(' ')
    },
    clicked(event) {
      //console.info(event)
      if (this.hasTouchBehaviours) {
        /*let ctx = document.createElement("canvas").getContext("2d");
        console.info("click behaviour detected!!!!")

        let x = event.pageX - this.$refs.asset.offsetLeft,
          y = event.pageY - this.$refs.asset.offsetTop,
          w = ctx.canvas.width = this.$refs.asset.width,
          h = ctx.canvas.height = this.$refs.asset.height,
          alpha;

        console.warn(x,y,w,h)

        // Draw image to canvas
        // and read Alpha channel value
        ctx.drawImage(this.$refs.asset, 0, 0, w, h);
        alpha = ctx.getImageData(x, y, 1, 1).data[3]; // [0]R [1]G [2]B [3]A

        // If pixel is transparent,
        // retrieve the element underneath and trigger it's click event
        if (alpha > 0) {
          console.info("CLICKED ON NON TRANSPARENT PIXEL")
        }*/

        this.executeBehaviours('click')
      }
    },
    executeBehaviours(type = null) {
      if (!type) {
        return
      }

      const clickBehaviours = this.getBehavioursByType(type)

      //console.warn(clickBehaviours)

      clickBehaviours.map((behaviour) => {
        behaviour.data.setActive = this.setEnabled
        behaviour.data.setInactive = this.setDisabled
        this.$emit(type, behaviour)
      })
    },
    initCueEvents() {
      const cueBehaviours = this.getBehavioursByType('cue')

      cueBehaviours.map((behaviour) => {
        const timeEvent = {
          id: this.$vnode.key,
          data: behaviour,
          setActive: this.setEnabled,
          setInactive: this.setDisabled,
          type: behaviour.data.type,
          start: parseInt(behaviour.data.cue) / 1000,
          finish: (parseInt(behaviour.data.cue) + parseInt(behaviour.data.duration)) / 1000,
        }

        //console.info(timeEvent)
        this.$emit('cue', timeEvent)
      })
    },
    initControlEvents() {
      const controlBehaviours = this.getBehavioursByKey('play-pause-on-click')

      if (controlBehaviours.length >= 1) {

        const controlEvent = {
          id: this.$vnode.key,
          data: controlBehaviours[0],
          setActive: this.setEnabled,
          setInactive: this.setDisabled,
        }

        this.$emit('control', controlEvent)
      }
    },
    setEnabled(behaviour) {
      //console.info("set Enabled init", this.status)
      console.error(behaviour.key, this.status, behaviour.data.src)
      if (this.status === this.STATUS_DISABLED) {

        if (behaviour.key === 'show-on-cue' || behaviour.key === 'highligth-on-cue' || behaviour.key === 'highligth-on-click') {
          //console.info(behaviour.data.type)
          if (behaviour.data.type === 'overlay' || behaviour.data.type === 'overlap') {
            this.currentOverlay = behaviour.data.src
            this.showOverlay = true
          }

          if (behaviour.data.type === 'replace') {
            this.currentImage = behaviour.data.src
          }
        }

        if (behaviour.key === 'play-pause-on-click') {
          this.currentImage = behaviour.data.src
        }

        this.status = this.STATUS_ENABLED
      }
      //console.info("set Enabled fin", this.status)
    },
    setDisabled(behaviour) {
      //console.info("set Disabled init", this.status)
      if (this.status === this.STATUS_ENABLED) {

        if (behaviour.key === 'show-on-cue' || behaviour.key === 'highligth-on-cue' || behaviour.key === 'highligth-on-click') {
          if (behaviour.data.type === 'overlay' || behaviour.data.type === 'overlap') {
            this.showOverlay = false
          }

          if (behaviour.data.type === 'replace') {
            this.currentImage = this.data.img
          }
        }

        if (behaviour.key === 'play-pause-on-click') {
          this.currentImage = this.data.img
        }

        this.status = this.STATUS_DISABLED
      }
      //console.info("set Disabled fin", this.status)
    },
    getViewClasses() {
      let classList = []
      if (this.asset.hasOwnProperty('h_flip') &&
        true === this.asset.h_flip) {
        classList.push('h-flipped')
      }

      return classList.join(' ').trim()
    }
  }
}
</script>
