<template>
  <div class="overlay-asset-container" v-if="show">
    <img class="overlay-asset" :style="sizeStyle(size)" :src="getAssetFullPath(image)" alt=""
         @click.prevent="avoidEvent"/>
  </div>
</template>

<script>
export default {
  name: "OverlayAsset",
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    src: {
      type: String,
      default: ''
    },
    scale: {
      type: Number,
      default: 1
    },
    duration: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      show: this.visible,
      image: this.src,
      size: this.scale,
      delay: this.duration
    }
  },
  methods: {
    getAssetFullPath(assetPath) {
      return process.env.VUE_APP_BASE_CDN + assetPath
    },
    sizeStyle(scale) {
      if (!scale || scale === 1) {
        return ''
      }

      return 'max-width: ' + (scale * 100) + '%'
    },
    avoidEvent() {

    }
  },
  mounted() {
    this.loaded = true
  },
  watch: {
    visible: function (val) {
      this.show = val
    },
    src: function (val) {
      this.image = val
    },
    scale: function (val) {
      this.size = val
    }
  }
}
</script>
