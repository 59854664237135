<template>
  <div
    v-if="value"
    class="text-toolbar"
  >
    <!--<div class="item">
      <b-select
        :value="config.family"
        placeholder="Tipo texto"
        size="is-small"
        @input="configChanged('family',$event)"
      >
        <option
          v-for="option in fontFamilies"
          :key="option.data"
          :value="option.data"
        >
          {{ option.label }}
        </option>
      </b-select>
    </div>-->
    <div class="item">
      <b-button
        v-for="option in aligns"
        :key="option.data"
        size="is-small"
        @click="configChanged('alignment',option.data)"
      >
        <b-icon
          pack="fas"
          :icon="option.icon"
        />
      </b-button>
    </div>
    <div class="item">
      <b-select
        :value="config.size"
        placeholder="Tamaño texto"
        size="is-small"
        @input="configChanged('size',$event)"
      >
        <option
          v-for="option in fontSizes"
          :key="option.data"
          :value="option.data"
        >
          {{ option.label }}
        </option>
      </b-select>
    </div>
    <div class="item">
      <b-colorpicker
        :value="config.color"
        size="is-small"
        representation="square"
        position="is-top-left"
        @input="configChanged('color',$event)"
      >
        <template #trigger>
          <button size="is-small">
            <b-icon
              pack="fas"
              icon="font"
              :style="{color: config.color}"
            />
          </button>
        </template>
      </b-colorpicker>
    </div>
    <div class="item">
      <b-colorpicker
        :value="config.backgroundColor"
        :alpha="true"
        size="is-small"
        representation="square"
        position="is-top-left"
        :color-formatter="hexaFormatter"
        @input="configChanged('backgroundColor',$event)"
      >
        <template #trigger>
          <button
            size="is-small"
            :style="{color: config.color, backgroundColor: config.backgroundColor}"
          >
            <b-icon
              pack="fas"
              icon="font"
            />
          </button>
        </template>
      </b-colorpicker>
    </div>
  </div>
</template>

<script>

export default {
  name: "TextToolbar",
  props: {
    value: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  emits: ['input'],
  data() {
    return {
      fontFamilies: [
        {label: 'Work Sans', data: '"Work Sans", sans-serif'},
        {label: 'Niagsol', data: '"Niagara Solid", sans-serif'},
        {label: 'Fishfingers', data: '"FISHfingers", sans-serif'},
      ],
      fontSizes: [
        {label: '12px', data: '12px'},
        {label: '14px', data: '14px'},
        {label: '16px', data: '16px'},
        {label: '18px', data: '18px'},
        {label: '20px', data: '20px'},
        {label: '22px', data: '22px'},
        {label: '24px', data: '24px'},
        {label: '26px', data: '26px'},
        {label: '30px', data: '30px'},
        {label: '34px', data: '34px'},
        {label: '38px', data: '38px'},
      ],
      aligns: [
        {label: 'Izquierda', data: 'start', icon: 'align-left'},
        {label: 'Centrar', data: 'center', icon: 'align-center'},
        {label: 'Derecha', data: 'right', icon: 'align-right'},
        {label: 'Justificado', data: 'justify', icon: 'align-justify'},
      ],
      vAligns: [
        {label: 'Arriba', data: 'flex-start', icon: 'align-left'},
        {label: 'Centrar', data: 'center', icon: 'align-center'},
        {label: 'Abajo', data: 'flex-end', icon: 'align-right'},
      ],
      config: {
        family: '"Work Sans", sans-serif',
        size: '12px',
        color: '#000000',
        backgroundColor: '#FFFFFF',
        align: 'left'
      }
    }
  },
  watch: {
    value() {
      this.updateLocalConfig()
    }
  },
  mounted() {
    this.updateLocalConfig()
  },
  methods: {
    updateLocalConfig() {
      if (!this.value) {
        return
      }

      if (this.value?.textConfig?.family) {
        this.config.family = this.value.textConfig.family
      }

      if (this.value?.textConfig?.color) {
        this.config.color = this.value.textConfig.color
      }

      if (this.value?.textConfig?.size) {
        this.config.size = this.value.textConfig.size
      }

      if (this.value?.textConfig?.backgroundColor) {
        this.config.backgroundColor = this.value.textConfig.backgroundColor
      }

      if (this.value?.textConfig?.align) {
        this.config.align = this.value.textConfig.align
      }
    },
    configChanged(key, value) {
      if (key === 'family') {
        this.config.family = value
      }

      if (key === 'size') {
        this.config.size = value
      }

      if (key === 'color') {
        this.config.color = value.toString('hex')
      }

      if (key === 'backgroundColor') {
        this.config.backgroundColor = value.toString('hexa')
      }

      if (key === 'alignment') {
        this.config.align = value
      }

      this.$emit('input', {...this.config})
    },
    hexaFormatter(color) {
      return color.toString('hexa')
    }
  }
}
</script>

<style scoped lang="scss">
.text-toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  display: flex;
  flex-direction: row;
  background-color: lightgrey;
  align-items: center;
  justify-content: center;

  .item {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
